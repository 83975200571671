/*
  ITI Internetworking Technologies Inc. 
  http://www.CanadianDomainRegistry.ca
  ELinks Version 4.0.0
 */
var ELinks,ELinkO;if(typeof ELinks==="undefined"){ELinks=function(){this.obj={
/* .......... ELinks Default Variable:Values .......... */
/* String */
DomainDefault:""
/* Array */
,DomainHosts:["www"]
/* String */
,LinkElement:"span"
/* String */
,LinkClass:"elink"
/* String */
,LinkStyle:""
/* Boolean or String */
,LinkTitle:false
/* .......... End Of Default Variable:Values .......... */
/* .......... Elinks Acknowledgement .......... */
/* Boolean */
,ELinksAck:false
/* String */
,ELinksAckColor:"navy"
/* String */
,ELinksAckSize:".9em"
/* .......... These apply only if you are using the ELinks Installer .......... */
/* Boolean */
,Installer:false
/* String */
,Language:"en"
/* Registration key */
,rKey:""
/* String */
,ELinksVersion:"4.0.0"};
this.src={eSET:this.obj.Installer,eLAN:this.obj.Language,eCON:0,eCNT:0,eDDD:false,eLLE:0,eLLC:0,eLDD:0,eLDH:0,eLDA:0,eLLS:0,eLTT:0,eLSC:0,eBoth:0,eDEP:[],eUK:[]};this.Installer=function(){if(this.src.eCON===0){var c,b=[],a=0;c=new RegExp("ELinks.js","i");b=document.getElementsByTagName("script");if(this.obj.Language===""){this.obj.Language="en"
}for(a=0;a<b.length;a+=1){if(b[a].src!==""&&b[a].src.search(c)!==-1){this.eSRC=b[a].src.replace(c,"");document.write('<script type="text/JavaScript" src="'+this.eSRC+'Installer.js"><\/script>');break}}this.src.eCON=1}};if(this.obj.Installer){this.Installer()}this.update=function(a){var f,e,d=0,c,b=[];
if(a.length%2===0){b={};for(d=0;d<a.length;d+=2){b[a[d]]=a[d+1]}ELinkO.update([b]);a=[]}for(d=0;d<a.length;d+=1){for(c in a[d]){if(typeof c==="string"){f=c.valueOf();e=a[d][c];switch(f.toLowerCase()){case"installer":if(ELinkO.src.eCON&&!e){ELinkO.src.eBoth=(ELinkO.src.eSET)?1:2;break}ELinkO.obj.Installer=e;
if(ELinkO.obj.Installer){if(!ELinkO.src.eCON){ELinkO.Installer()}else{ELinkO.src.eBoth=(ELinkO.src.eSET)?1:2}}break;case"linkelement":ELinkO.obj.LinkElement=e;ELinkO.src.eLLE=1;break;case"linkclass":ELinkO.obj.LinkClass=e;ELinkO.src.eLLC=1;break;case"domaindefault":ELinkO.obj.DomainDefault=e;ELinkO.src.eLDD=1;
break;case"domainhosts":ELinkO.obj.DomainHosts=e;ELinkO.src.eLDH=1;break;case"linkstyle":ELinkO.obj.LinkStyle=e;ELinkO.src.eLLS=1;break;case"linktitle":ELinkO.obj.LinkTitle=e;ELinkO.src.eLTT=1;break;case"rkey":ELinkO.obj.rKey=e;break;case"language":ELinkO.obj.Language=e;break;case"elinksack":ELinkO.obj.ELinksAck=e;
break;case"elinksackcolor":ELinkO.obj.ELinksAckColor=e;break;case"elinksacksize":ELinkO.obj.ELinksAckSize=e;break;default:ELinkO.src.eUK[ELinkO.src.eUK.length]=f}}}}}};ELinkO=new ELinks();ELinks=null;ELinks=function(){if(arguments.length){ELinkO.update(arguments)}else{var m,S,Q,O,F,L,z,r,n,P,U,h,H,u,G,d,I,c,D,V,C,E,p,K,A,x=["to","cc","bcc","subject","body","linktext","text","class","css","tt"],o="ma",J="t",y=":",M="=",f,l,s,R,W,B,q,b,a,N,g;
l=ELinkO.obj.Installer;if(ELinkO.obj.DomainDefault===""){ELinkO.src.eDDD=true;ELinkO.obj.DomainDefault=document.domain}if(l){if(typeof ELinkI==="undefined"){l=false;alert("No Installer File.")}else{s=new ELinkI()}if(!s.LD()){s.ER();l=false}}else{if(ELinkO.src.eCON){s=new ELinkI();s.VA("","","00")}}if(l&&ELinkO.src.eUK.length){s.VA(ELinkO.src.eUK,"","50")
}String.prototype.ELinkTrim=function(){return this.replace(/^\s*|\s*$/g,"")};f=function(i,t){var ac,e,Y,Z,k,aa,ab,X=false;if(l){s.VA(i,t)}ac=i.split(" ");e=ac.length;if(e===1){Y=ELinkO.obj.DomainDefault.ELinkTrim().replace(/\s/g,".");if(ELinkO.src.eDDD){ab=Y.split(".");for(k=0;k<ab.length;k+=1){for(aa=0;
aa<ELinkO.obj.DomainHosts.length;aa+=1){if(ELinkO.obj.DomainHosts[aa]===ab[k]){Y=Y.replace(ELinkO.obj.DomainHosts[aa]+".","");X=true;break}else{X=false}}if(!X){break}}}Z=ac[0]+"@"+Y}else{Z=i.replace(/\s+/,"@");Z=Z.replace(/\s+/g,".")}return Z};m=document.getElementsByTagName(ELinkO.obj.LinkElement);if(m.length>0){W=0;
for(S in m){if(typeof m[S]==="object"){z=m[S];if(z.className===ELinkO.obj.LinkClass){ELinkO.src.eCNT+=1;if(z.title.ELinkTrim()===""){if(l){s.VA("",z,"04")}}else{H=[];u=[];G=[];A=[];h=d=I=c=D=V=C=p=E="";L=z.title.match(/;|,/);L=z.title.split(L);for(Q=0;Q<L.length;Q+=1){if(L[Q].ELinkTrim()!==""){r=L[Q].split("=");
P=r[0].ELinkTrim();if(r.length===1){n=P.split(" ");F=false;if(n.length>1){for(O=0;O<x.length;O+=1){if(n[0].ELinkTrim().toLowerCase()===x[O]){U=P.replace(RegExp(x[O],"i"),"").ELinkTrim();P=x[O];F=true;break}}}if(!F){U=P;if(H.length===0){P="to"}else{P="cc"}}}else{U=r[1].ELinkTrim()}if(U.ELinkTrim()!==""){switch(P.toLowerCase()){case"to":H[H.length]=f(U,z);
A[A.length]=H[H.length-1];break;case"cc":u[u.length]=f(U,z);A[A.length]=u[u.length-1];break;case"bcc":G[G.length]=f(U,z);A[A.length]=G[G.length-1];break;case"subject":if(V===""){V="subject"+M+U+"&"}else{h+="subject "}break;case"body":if(C===""){C="body"+M+U}else{h+="body "}break;case"linktext":case"text":if(D===""){D=U
}else{h+="text\u00A0and/or\u00A0linktext "}break;case"class":case"css":if(p===""){p=U}else{h+="css\u00A0and/or\u00A0class "}break;case"tt":if(E===""){E=U}else{h+="LinkTitle "}break;default:if(l){s.VA(r[0],z,"05")}}}else{if(l){s.VA(r[0],z,"03")}}}}if(l){s.VB(H,h,p,z)}for(Q=0;Q<H.length;Q+=1){if(Q>-1){d+=H[Q]+";"
}}d=d.substr(0,d.length-1);for(Q=0;Q<u.length;Q+=1){if(Q>-1){I+=u[Q]+";"}}if(I!==""){I="cc"+M+I.substr(0,I.length-1)+"&"}for(Q=0;Q<G.length;Q+=1){if(Q>-1){c+=G[Q]+";"}}if(c!==""){c="bcc"+M+c.substr(0,c.length-1)+"&"}K=o+"il"+J+"o"+y+d;if(I!==""||c!==""||V!==""||C!==""){K+="?"+I+c+V+C;if(K.slice(-1)==="&"){K=K.slice(0,-1)
}}if(D===""&&H[0]!==undefined){D=H[0]}if(E==="false"||(E===""&&ELinkO.obj.LinkTitle===false)){try{z.removeAttribute("title")}catch(T){z.title=""}}else{if(E!==""){z.title=(E==="true")?H[0]:E}else{z.title=(ELinkO.obj.LinkTitle===true)?H[0]:ELinkO.obj.LinkTitle}}if(p!==""||ELinkO.obj.LinkStyle!==""){z.className=(p!=="")?p:ELinkO.obj.LinkStyle
}R=document.createElement("A");z.innerHTML="";R.href=K;R.innerHTML=D;try{q=z.getAttribute("className");if(q===ELinkO.obj.LinkClass||q.toLowerCase()==="default"){z.removeAttribute("className")}try{R.mergeAttributes(z)}catch(w){}}catch(v){q=z.getAttribute("class");if(q===ELinkO.obj.LinkClass||q.toLowerCase()==="default"){z.removeAttribute("class")
}B=z.attributes;for(Q=0;Q<B.length;Q+=1){if(B[Q].specified){R.setAttribute(B[Q].name,B[Q].value)}}}z.parentNode.insertBefore(R,z);z.parentNode.replaceChild(document.createElement(ELinkO.obj.LinkElement),z)}}}}}if(l){s.MT();s.PE()}if(document.getElementById("ELinksAck")===null){b=ELinkO.obj.ELinksAck?"block":"none";
a=ELinkO.obj.ELinksAckSize;N=ELinkO.obj.ELinksAckColor;ELinkO=document.getElementsByTagName("body")[0];g=document.createElement("div");g.style.marginTop="20px";g.style.marginBottom="20px";g.style.textAlign="center";g.style.display=b;g.id="ELinksAck";R=document.createElement("a");R.href="http://www.CanadianDomainRegistry.ca";
R.title="\u00AB   CanadianDomainRegistry.ca   \u00BB";R.style.color=N;R.style.textDecoration="none";R.style.fontSize=a;R.innerHTML="Our email addresses are protected by ELinks \u00A9";g.appendChild(R);ELinkO.appendChild(g)}ELinks=function(){};ELinkO=null}}}if(window.addEventListener){window.addEventListener("load",function(){ELinks();
window.removeEventListener("load",ELinks,true)},true)}else{if(window.attachEvent){window.attachEvent("onload",function(){ELinks();window.detachEvent("onload",ELinks)})}};